const middleware = {}

middleware['4w-ka-prime'] = require('../src/middleware/4w-ka-prime.ts');
middleware['4w-ka-prime'] = middleware['4w-ka-prime'].default || middleware['4w-ka-prime']

middleware['auto-one'] = require('../src/middleware/auto-one.ts');
middleware['auto-one'] = middleware['auto-one'].default || middleware['auto-one']

middleware['bquik-2024'] = require('../src/middleware/bquik-2024.ts');
middleware['bquik-2024'] = middleware['bquik-2024'].default || middleware['bquik-2024']

middleware['campaign-dealer-refer'] = require('../src/middleware/campaign-dealer-refer.ts');
middleware['campaign-dealer-refer'] = middleware['campaign-dealer-refer'].default || middleware['campaign-dealer-refer']

middleware['contents/index'] = require('../src/middleware/contents/index.ts');
middleware['contents/index'] = middleware['contents/index'].default || middleware['contents/index']

middleware['ddo-retention-line'] = require('../src/middleware/ddo-retention-line.ts');
middleware['ddo-retention-line'] = middleware['ddo-retention-line'].default || middleware['ddo-retention-line']

middleware['ddo-retention'] = require('../src/middleware/ddo-retention.ts');
middleware['ddo-retention'] = middleware['ddo-retention'].default || middleware['ddo-retention']

middleware['dealer-refer-mc'] = require('../src/middleware/dealer-refer-mc.ts');
middleware['dealer-refer-mc'] = middleware['dealer-refer-mc'].default || middleware['dealer-refer-mc']

middleware['employee2w'] = require('../src/middleware/employee2w.ts');
middleware['employee2w'] = middleware['employee2w'].default || middleware['employee2w']

middleware['employee4w-2022-q4'] = require('../src/middleware/employee4w-2022-q4.ts');
middleware['employee4w-2022-q4'] = middleware['employee4w-2022-q4'].default || middleware['employee4w-2022-q4']

middleware['employee4w'] = require('../src/middleware/employee4w.ts');
middleware['employee4w'] = middleware['employee4w'].default || middleware['employee4w']

middleware['foodpanda-c4c2025'] = require('../src/middleware/foodpanda-c4c2025.ts');
middleware['foodpanda-c4c2025'] = middleware['foodpanda-c4c2025'].default || middleware['foodpanda-c4c2025']

middleware['gobear'] = require('../src/middleware/gobear.ts');
middleware['gobear'] = middleware['gobear'].default || middleware['gobear']

middleware['gpf-2w'] = require('../src/middleware/gpf-2w.ts');
middleware['gpf-2w'] = middleware['gpf-2w'].default || middleware['gpf-2w']

middleware['gpf-4w'] = require('../src/middleware/gpf-4w.ts');
middleware['gpf-4w'] = middleware['gpf-4w'].default || middleware['gpf-4w']

middleware['ka-the1'] = require('../src/middleware/ka-the1.ts');
middleware['ka-the1'] = middleware['ka-the1'].default || middleware['ka-the1']

middleware['ka-true-rider'] = require('../src/middleware/ka-true-rider.ts');
middleware['ka-true-rider'] = middleware['ka-true-rider'].default || middleware['ka-true-rider']

middleware['ka-true'] = require('../src/middleware/ka-true.ts');
middleware['ka-true'] = middleware['ka-true'].default || middleware['ka-true']

middleware['kma-one-retail-x-ddo'] = require('../src/middleware/kma-one-retail-x-ddo.ts');
middleware['kma-one-retail-x-ddo'] = middleware['kma-one-retail-x-ddo'].default || middleware['kma-one-retail-x-ddo']

middleware['lalamove-c4c2025'] = require('../src/middleware/lalamove-c4c2025.ts');
middleware['lalamove-c4c2025'] = middleware['lalamove-c4c2025'].default || middleware['lalamove-c4c2025']

middleware['member-get-member'] = require('../src/middleware/member-get-member.ts');
middleware['member-get-member'] = middleware['member-get-member'].default || middleware['member-get-member']

middleware['normal-plus'] = require('../src/middleware/normal-plus.ts');
middleware['normal-plus'] = middleware['normal-plus'].default || middleware['normal-plus']

middleware['pay-in-time'] = require('../src/middleware/pay-in-time.ts');
middleware['pay-in-time'] = middleware['pay-in-time'].default || middleware['pay-in-time']

middleware['payroll'] = require('../src/middleware/payroll.ts');
middleware['payroll'] = middleware['payroll'].default || middleware['payroll']

middleware['promotions/all-campaign'] = require('../src/middleware/promotions/all-campaign.js');
middleware['promotions/all-campaign'] = middleware['promotions/all-campaign'].default || middleware['promotions/all-campaign']

middleware['revolving-x-gpf'] = require('../src/middleware/revolving-x-gpf.ts');
middleware['revolving-x-gpf'] = middleware['revolving-x-gpf'].default || middleware['revolving-x-gpf']

middleware['shopee-food'] = require('../src/middleware/shopee-food.ts');
middleware['shopee-food'] = middleware['shopee-food'].default || middleware['shopee-food']

middleware['susco'] = require('../src/middleware/susco.ts');
middleware['susco'] = middleware['susco'].default || middleware['susco']

middleware['theone'] = require('../src/middleware/theone.ts');
middleware['theone'] = middleware['theone'].default || middleware['theone']

middleware['theone2w'] = require('../src/middleware/theone2w.ts');
middleware['theone2w'] = middleware['theone2w'].default || middleware['theone2w']

middleware['theonelifttime'] = require('../src/middleware/theonelifttime.ts');
middleware['theonelifttime'] = middleware['theonelifttime'].default || middleware['theonelifttime']

middleware['theoneline'] = require('../src/middleware/theoneline.ts');
middleware['theoneline'] = middleware['theoneline'].default || middleware['theoneline']

middleware['true-twenty-years-q4'] = require('../src/middleware/true-twenty-years-q4.ts');
middleware['true-twenty-years-q4'] = middleware['true-twenty-years-q4'].default || middleware['true-twenty-years-q4']

middleware['welcomeback-ab'] = require('../src/middleware/welcomeback-ab.ts');
middleware['welcomeback-ab'] = middleware['welcomeback-ab'].default || middleware['welcomeback-ab']

export default middleware
