/* eslint-disable */
export default ({ app }, inject) => {
  ;(function(w, d, t) {
    w.TiktokAnalyticsObject = t
    var ttq = (w[t] = w[t] || [])
    ttq.methods = [
      'page',
      'track',
      'identify',
      'instances',
      'debug',
      'on',
      'off',
      'once',
      'ready',
      'alias',
      'group',
      'enableCookie',
      'disableCookie'
    ]
    ttq.setAndDefer = function(t, e) {
      t[e] = function() {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
      }
    }
    for (var i = 0; i < ttq.methods.length; i++) {
      ttq.setAndDefer(ttq, ttq.methods[i])
    }
    ttq.load = function(e, n) {
      var i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
      ttq._i = ttq._i || {}
      ttq._i[e] = []
      ttq._i[e]._u = i
      ttq._t = ttq._t || {}
      ttq._t[e] = +new Date()
      ttq._o = ttq._o || {}
      ttq._o[e] = n || {}
      var script = d.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = i + '?sdkid=' + e + '&lib=' + t
      var firstScript = d.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(script, firstScript)
    }
    ttq.load('CLMRCHJC77U5HE4E40D0') // Replace with your TikTok Pixel ID
    ttq.page()

    // Inject ttq globally
    inject('ttq', ttq)
  })(window, document, 'ttq')
}
