<template>
  <div class="revamp-container">
    <div class="revamp-wrapper-carousel">
      <b-carousel
        id="revamp-carousel-promotions"
        v-model="slide"
        :interval="timeInterval"
        controls
        indicators
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="item in promotions"
          :id="item.dataTest"
          :key="item.dataTest"
        >
          <div
            class="wrap-banner"
            :data-test="item.dataTest"
            @click="bannerClick(item)"
          >
            <Picture
              :image-file-name="item.detailImage.mobile"
              :tablet-image-file-name="item.carouselImage.desktop"
              :desktop-image-file-name="item.carouselImage.desktop"
              :alt="item.carouselImage.alt"
              class="promotion-img"
              :img-id="item.dataTest"
              width="100%"
              heigth="100%"
              :data-test="item.dataTest"
            />
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="revamp-banner-promote">
      <div class="wrap-image">
        <img
          src="~/assets/images/revamp/girl-revamp-banner.svg"
          alt="จบทุกปัญหาทางการเงินคาร์ ฟอร์ แคช ช่วยได้ สินเชื่อรถ รีไฟแนนซ์รถ อนุมัติไว ดอกเบี้ยต่ำ"
        />
      </div>
      <div class="control-flex">
        <div class="text-promote">
          <div>
            <h1 class="first-text">จบทุกปัญหาทางการเงิน</h1>
            <h1 class="special-text">คาร์ ฟอร์ แคช ช่วยได้</h1>
          </div>
          <h1 class="last-text">
            สินเชื่อรถ รีไฟแนนซ์รถ <br class="mobile" />อนุมัติไว ดอกเบี้ยต่ำ
          </h1>
        </div>
        <div class="wrapper-btn">
          <Button id="btn-calculate" class="calculate" @click="handleClickCal()"
            >ประเมินวงเงิน</Button
          >
          <Button id="btn-loan-request" class="loan" @click="handleClickLoan()"
            >ขอสินเชื่อ</Button
          >
        </div>
      </div>
    </div>
    <div class="revamp-key-value section-detail">
      <h2>ทำไมต้อง Car4Cash</h2>
      <div class="grid-column">
        <div class="item-column">
          <div class="image">
            <img
              src="~/assets/images/revamp/img-column1.svg"
              alt="ให้วงเงินสูง"
            />
          </div>
          <div>
            <h3>ให้วงเงินสูง</h3>
            <p>
              วงเงินประเมินตามราคากลางรถ<br />เพื่อได้รับอนุมัติวงเงินกู้สูงสุดเต็ม
              100%<br />ของราคารถ
            </p>
          </div>
        </div>
        <div class="item-column">
          <div class="image">
            <img src="~/assets/images/revamp/img-column2.svg" at="อนุมัติไว" />
          </div>
          <div>
            <h3>อนุมัติไว</h3>
            <p>
              อนุมัติง่ายเมื่อเอกสารครบ<br />อายุรถตรงตามเกณฑ์<br />ไม่มีสลิปเงินเดือนก็ขอกู้ได้
            </p>
          </div>
        </div>
        <div class="item-column">
          <div class="image">
            <img
              src="~/assets/images/revamp/img-column3.svg"
              alt="ดอกเบี้ยต่ำ"
            />
          </div>
          <div>
            <h3>ดอกเบี้ยต่ำ</h3>
            <p v-if="$features('DWB-1391-UPDATE-FAQ-F')">
              ดอกเบี้ยสินเชื่อรถยนต์เริ่มต้น 0.27%,
              <br />รีไฟแนนซ์บิ๊กไบค์เริ่ม 0.46%, <br />สินเชื่อมอเตอร์ไซค์เริ่ม
              1.07% ต่อเดือน
            </p>
            <p v-else>
              ดอกเบี้ยสินเชื่อรถยนต์เริ่มต้น 0.27%,
              <br />รีไฟแนนซ์บิ๊กไบค์เริ่ม 0.38%, <br />สินเชื่อมอเตอร์ไซค์เริ่ม
              1.07% ต่อเดือน
            </p>
          </div>
        </div>
        <div class="item-column">
          <div class="image">
            <img src="~/assets/images/revamp/img-column4.svg" alt="ผ่อนสบาย" />
          </div>
          <div>
            <h3>ผ่อนสบาย</h3>
            <p>
              รถยนต์ผ่อนได้ถึง 84 เดือน, <br />บิ๊กไบค์ผ่อนนาน 48 เดือน,
              <br />มอเตอร์ไซค์ผ่อนสบายถึง 36 เดือน
            </p>
          </div>
        </div>
      </div>
    </div>
    <Contents
      id="revamp-blogs"
      data-test="contents-section"
      homepage
      events="homepage"
    />
    <div class="faq-container">
      <div class="topbar">
        <TitleMedium id="faq-header" color="outer-space" as="h2">
          คำถามที่พบบ่อยเกี่ยวกับ<span id="faq-header" class="wrap-text"
            >รีไฟแนนซ์รถ</span
          >
        </TitleMedium>
        <nuxt-link
          data-test="faq-read-all"
          class="read-all"
          to="/frequently-asked-questions"
        >
          อ่านทั้งหมด
        </nuxt-link>
      </div>
      <Accordion class="faq-accordion" :items="faqItems" gototop="no" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import jsQR from 'jsqr'
import { filter, get, isEmpty } from 'lodash'
import { isWithinInterval, isAfter } from 'date-fns'
import Contents from '../../pages/contents/footer-contents.vue'
import Promotions from '~/pages/promotions/configs-promotions'
import Picture from '~/components/commons/bases/picture/picture.vue'
import Button from '~/components/commons/bases/button/button.vue'
import Accordion from '~/components/commons/organisms/accordion/accordion.vue'
import { TextBody, TitleMedium } from '~/components/commons/bases/typography'
import { List, OrderList, UnorderList } from '~/components/commons/bases/lists'

export default Vue.extend({
  name: 'HomeRevampVersion',
  components: {
    Contents,
    Picture,
    Button,
    Accordion,
    TitleMedium
  },
  data() {
    const promotions = filter(Promotions, promotion => this.onTime(promotion))
    return {
      promotions,
      slide: 0,
      timeInterval: 5000,
      sliding: null,
      refCode: null,
      vehicle: ['BB'],
      faqItems: [
        {
          title: 'รีไฟแนนซ์รถต่างจากการเช่าซื้อรถ อย่างไร',
          content: (
            <div>
              <TextBody class="with-bottom-rhythm">
                การรีไฟแนนซ์รถ คือ การนำรถของตนเองมาเป็นหลักทรัพย์ค้ำประกัน
                เพื่อขอสินเชื่อใหม่ ไม่ว่าจะเพื่อเสริมสภาพคล่องทางการเงิน
                หรือนำเงินก้อนไปใช้จ่ายตามความต้องการ ซึ่งการรีไฟแนนซ์ที่ว่านี้
                เราเลือกได้ว่าต้องการดอกเบี้ยแบบคงที่ หรือลดต้นลดดอก
                ซึ่งเป็นตัวเลือกที่ช่วยให้จัดการภาระการเงินได้อย่างมีประสิทธิภาพ
              </TextBody>
              <TextBody class="with-bottom-rhythm">
                ส่วนการเช่าซื้อรถ เป็นสินเชื่อสำหรับผู้ที่ประสงค์จะซื้อรถ
                โดยต้องการผ่อนชำระเป็นงวด ๆ เมื่อชำระครบ
                กรรมสิทธิ์ในรถจะเป็นของลูกค้าทันที ได้ทั้งรถใหม่ป้ายแดง
                หรือรถใช้แล้ว
              </TextBody>
              <TextBody>
                การจะเลือกรีไฟแนนซ์รถ ที่ไหนดีนั้น
                อยากให้ดูสิ่งที่เราต้องการที่สุดเป็นหลัก เช่น
                เราต้องการเงินด่วนจริง ๆ
                ที่ไหนอนุมัติสินเชื่อนำรถมาแลกเงินได้ไวสุด
                และได้ดอกเบี้ยในระดับที่เรารับได้(ซึ่งดอกเบี้ยอาจจะไม่ถูกที่สุด)
                ที่สำคัญมีการเปรียบเทียบดอกเบี้ยเงินกู้ให้เห็นทั้งแบบดอกเบี้ยคงที่
                และดอกเบี้ยลดต้นลดดอกก็จะยิ่งเพิ่มความน่าเชื่อถือได้ดีที่สุด
              </TextBody>
            </div>
          )
        },
        {
          title: 'อยากรีไฟแนนซ์รถที่ยังผ่อนไม่หมดทำได้ไหม',
          content: (
            <div>
              <TextBody>
                ทำได้อยู่แล้ว เราอยู่ในยุคที่ผู้บริโภคเลือกได้
                กรณีรีไฟแนนซ์รถที่ยังผ่อนไม่หมด
                ขั้นตอนจะเริ่มจากปิดสัญญาเก่าจากไฟแนนซ์ที่เราผ่อนอยู่ให้เรียบร้อยก่อน
                (ถามจากไฟแนนซ์ที่เดิมก่อนว่าเป็นเงินเท่าไหร่
                คุ้มไหมที่จะไปรีไฟแนนซ์กับที่ใหม่)
              </TextBody>
              <TextBody>
                ติดต่อรีไฟแนนซ์กับที่ใหม่
                ที่เราต้องรู้ว่าค่างวดในการผ่อนถูกลงหรือใกล้เคียงกับยอดเดิมที่จ่ายอยู่
                แต่เราได้รับยอดสินเชื่อที่มากขึ้นกว่าเดิม เพราะนั่นคือ Cash Out
                หรือเงินส่วนต่างที่เราได้รับเพิ่มจากการรีไฟแนนซ์
                โปรโมชันที่มีตรงกับความต้องการของเราจริง ๆ เช่น
                ผ่อนได้นานขึ้นเพื่อแบ่งเบาภาระในการผ่อนแต่ละงวดลง
                หรือได้รับอัตราดอกเบี้ยพิเศษเมื่อเทียบกับที่อื่น ๆ
                เพราะถ้าเราไม่ได้อะไรที่ดีขึ้นกว่าเดิม
                ก็ไม่คุ้มที่ต้องไปรีไฟแนนซ์เพื่อจ่ายค่าปิดสัญญาไฟแนนซ์ก่อนกำหนดเลย
              </TextBody>
            </div>
          )
        },
        {
          title: 'คุณสมบัติผู้ขอสินเชื่อรีไฟแนนซ์รถ',
          content: (
            <div>
              <TextBody weight="500">
                คุณสมบัติผู้ขอสินเชื่อรีไฟแนนซ์รถบิ๊ก ไบค์ และมอเตอร์ไซค์
              </TextBody>

              <OrderList>
                <TextBody as={List}>
                  มีสัญชาติไทย อายุตั้งแต่ 20 ปีบริบูรณ์ ถึง 65 ปีบริบูรณ์ (คาร์
                  ฟอร์ แคช บิ๊ก ไบค์ และมอเตอร์ไซค์ พร้อมใช้ อายุ 20 - 60 ปี)
                  ในวันทำสัญญา
                </TextBody>
                <TextBody as={List}>
                  มีอาชีพและรายได้ที่สามารถตรวจสอบได้ เช่น พนักงานบริษัท,
                  ข้าราชการ/พนักงานรัฐวิสาหกิจ และอาชีพอื่นๆ
                  ที่มีเอกสารแสดงรายได้
                </TextBody>
                <TextBody as={List}>มีรายได้ขั้นต่ำตามเกณฑ์ด้านล่าง</TextBody>
                <UnorderList>
                  <TextBody as={List}>
                    สำหรับรถบิ๊ก ไบค์ : รายได้ขั้นต่ำ 15,000 บาท/เดือน
                    โดยรายได้รวมของผู้ขอสินเชื่อต้องไม่น้อยกว่า 3
                    เท่าของค่างวดต่อเดือน
                  </TextBody>
                  <TextBody as={List}>
                    สำหรับรถมอเตอร์ไซค์ : รายได้ขั้นต่ำ 9,000 บาท/เดือน
                    โดยรายได้รวมของผู้ขอสินเชื่อต้องไม่น้อยกว่า 3
                    เท่าของค่างวดต่อเดือน
                  </TextBody>
                </UnorderList>
              </OrderList>

              <TextBody weight="500">
                คุณสมบัติผู้ขอสินเชื่อรีไฟแนนซ์รถยนต์
              </TextBody>
              <OrderList>
                <TextBody as={List}>
                  มีสัญชาติไทย อายุตั้งแต่ 20 ปีบริบูรณ์ ถึง 65 ปีบริบูรณ์ อายุ
                  20 – 65 ปี (คาร์ ฟอร์ แคช พร้อมใช้ อายุ 20 - 60 ปี)
                  ในวันทำสัญญา
                </TextBody>
                <TextBody as={List}>
                  มีอาชีพและรายได้ที่สามารถตรวจสอบได้ เช่น พนักงานบริษัท,
                  ข้าราชการ/พนักงานรัฐวิสาหกิจ และอาชีพอื่นๆ
                  ที่มีเอกสารแสดงรายได้
                </TextBody>
                <TextBody as={List}>
                  มีรายได้ขั้นต่ำตั้งแต่ 10,000 บาทขึ้นไป
                </TextBody>
              </OrderList>
            </div>
          )
        },
        {
          title: 'วิธีการขอสินเชื่อรถรีไฟแนนซ์รถ',
          content: (
            <div>
              <TextBody weight="500">
                1. ขั้นตอนการรีไฟแนนซ์รถผ่านช่องทางเว็บไซต์ Car4Cash
              </TextBody>
              <OrderList>
                <TextBody>
                  1.1 กรอกข้อมูลสมัครสินเชื่อที่เว็บไซต์
                  <a
                    href="https://www.car4cash.com/lead-form"
                    class="link-properties"
                    target="_blank"
                  >
                    <font class="link">
                      &nbsp;www.car4cash.com/lead-form &nbsp;
                    </font>
                  </a>
                  แล้วรอการติดต่อกลับจากเจ้าหน้าที่ภายใน 1 ชม. ในวัน
                  และเวลาทำการ เพื่อสอบถามข้อมูลเพิ่มเติม
                </TextBody>
                <TextBody>
                  1.2 ทำนัดหมายเจ้าหน้าที่เพื่อส่งเอกสารเพิ่มเติม
                  พร้อมประเมินราคารถ
                </TextBody>
                <TextBody>
                  1.3 เมื่อเอกสารครบถ้วน รอผลการอนุมัติสินเชื่อภายใน 1 วัน
                </TextBody>
                <TextBody>
                  1.4 ตรวจสภาพรถยนต์ โอนเปลี่ยนชื่อผู้ถือกรรมสิทธิ์ในเล่มทะเบียน
                  / หรือตรวจสอบสมุดคู่มือจดทะเบียนรถ
                </TextBody>
                <TextBody class="with-bottom-rhythm">
                  1.5 รับโอนเงินเข้าบัญชีภายใน 1 วันทำการ
                </TextBody>
              </OrderList>
              <TextBody weight="500">
                2. ขั้นตอนการรีไฟแนนซ์รถผ่านช่องทางแอปพลิเคชัน GO by Krungsri
                Auto
              </TextBody>
              <OrderList>
                <TextBody>2.1 เข้าแอปพลิเคชัน GO by Krungsri Auto</TextBody>

                <TextBody>2.2 เลือก คาร์ ฟอร์ แคช</TextBody>
                <TextBody>2.3 ประเมินราคารถเบื้องต้น</TextBody>
                <TextBody>2.4 กรอกข้อมูลสมัครสินเชื่อ</TextBody>
                <TextBody>
                  2.5 เจ้าหน้าที่โทรติดต่อกลับภายใน 1 ชม. ในวัน และเวลาทำการ
                  เพื่อสอบถามข้อมูลเพิ่มเติม
                </TextBody>
                <TextBody>
                  2.6 ทำนัดหมายเจ้าหน้าที่เพื่อส่งเอกสารเพิ่มเติม
                  พร้อมประเมินราคารถ
                </TextBody>
                <TextBody>
                  2.7 เมื่อเอกสารครบถ้วน รอผลการอนุมัติสินเชื่อภายใน 1 วัน
                </TextBody>
                <TextBody>
                  2.8 ตรวจสภาพรถยนต์ โอนเปลี่ยนชื่อผู้ถือกรรมสิทธิ์ในเล่มทะเบียน
                  / หรือตรวจสอบสมุดคู่มือจดทะเบียนรถ
                </TextBody>
                <TextBody class="with-bottom-rhythm">
                  2.9 รับโอนเงินเข้าบัญชีภายใน 1 วันทำการ
                </TextBody>
              </OrderList>
              <TextBody weight="500">
                3. ขั้นตอนการรีไฟแนนซ์รถผ่าน กรุงศรี ออโต้
                บริการจัดทำสัญญาถึงบ้านหรือการเดินทางไปที่สาขาใกล้บ้าน
              </TextBody>
              <OrderList>
                <TextBody>
                  3.1 สามารถติดต่อสอบถามข้อมูลหรือติดต่อเพื่อเซ็นสัญญาได้ที่สาขา
                  กรุงศรี ออโต้ ที่สะดวก
                </TextBody>
                <TextBody>
                  3.2 ทำนัดหมายเจ้าหน้าที่เพื่อส่งเอกสารเพิ่มเติม
                  พร้อมประเมินราคารถ โดยสามารถเลือกบริการจัดทำสัญญาถึงบ้าน
                  หรือการเดินทางไปที่สาขาใกล้บ้าน
                </TextBody>
                <TextBody class="with-bottom-rhythm">
                  3.3 เมื่อเอกสารครบถ้วน รอผลการอนุมัติสินเชื่อภายใน 1 วัน
                </TextBody>
                <TextBody>3.4 รับโอนเงินเข้าบัญชีภายใน 1 วันทำการ</TextBody>
              </OrderList>

              <TextBody>
                สอบถามข้อมูลเพิ่มเติมหรือติดต่อเจ้าหน้าที่โทร 02-740-7400
                ตลอดเวลาทำการ
              </TextBody>
            </div>
          )
        },
        {
          title: 'รีไฟแนนซ์รถใช้เอกสารอะไรบ้าง',
          content: (
            <div>
              <TextBody weight="500">
                เอกสารที่ต้องใช้ในการรีไฟแนนซ์รถสำหรับบุคคลธรรมดา
              </TextBody>
              <OrderList>
                <TextBody as={List}>
                  บัตรประชาชนตัวจริงพร้อมสำเนาบัตรประชาชนจำนวน 2 ฉบับ
                </TextBody>
                <TextBody as={List}>
                  เอกสารแสดงรายได้อย่างใดอย่างหนึ่งต่อไปนี้
                  <TextBody>
                    • สลิปเงินเดือนหรือหนังสือรับรองรายได้ฉบับล่าสุด
                    หรือย้อนหลังไม่เกิน 2 เดือน
                  </TextBody>
                  <TextBody>
                    • สำเนาสมุดบัญชีเงินฝากที่เป็นบัญชีนำเข้าเงินเดือนย้อนหลัง 3
                    เดือนล่าสุด
                  </TextBody>
                  <TextBody>
                    • สำเนาสมุดบัญชีออมทรัพย์หรือกระแสรายวันย้อนหลัง 3
                    เดือนล่าสุด
                  </TextBody>
                </TextBody>
                <TextBody as={List}>
                  แผนที่ตั้งของที่อยู่อาศัยและที่ทำงาน
                </TextBody>
                <TextBody as={List}>
                  สำเนาสมุดบัญชีหน้าแรกของธนาคารที่ต้องโอนเงินเข้า
                </TextBody>
                <TextBody as={List} class="with-bottom-rhythm">
                  สมุดคู่มือจดทะเบียนรถ
                </TextBody>
              </OrderList>
              <TextBody weight="500">
                เอกสารที่ต้องใช้ในการรีไฟแนนซ์รถสำหรับเจ้าของกิจการ/นิติบุคคล
              </TextBody>
              <OrderList>
                <TextBody as={List}>
                  หนังสือรับรองนิติบุคคลหรือทะเบียนการค้า (ฉบับปัจจุบัน) จำนวน 2
                  ชุด
                </TextBody>
                <TextBody as={List}>สำเนา ภ.พ. 20</TextBody>
                <TextBody as={List}>
                  สำเนาบัตรประชาชนของกรรมการที่มีอำนาจลงนามนิติบุคคล
                </TextBody>
                <TextBody as={List}>สำเนาบัตรประชาชนของผู้ค้ำประกัน</TextBody>
                <TextBody as={List}>
                  แผนที่ตั้งของที่อยู่อาศัยหรือที่ตั้งกิจการ
                </TextBody>
                <TextBody as={List}>
                  งบการเงินหรือสำเนาเงินฝากออมทรัพย์/กระแสรายวัน ย้อนหลัง 3
                  เดือนล่าสุด
                </TextBody>
                <TextBody as={List}>
                  บัญชีรายชื่อผู้ถือหุ้น (บอจ. 5) ยกเว้น
                  บริษัทที่จดทะเบียนในตลาดหลักทรัพย์
                </TextBody>
                <TextBody as={List}>
                  สำเนาสมุดบัญชีหน้าแรกของธนาคารที่ต้องโอนเงินเข้า
                </TextBody>
                <TextBody as={List}>สมุดคู่มือจดทะเบียนรถ</TextBody>
              </OrderList>
            </div>
          )
        }
      ]
    }
  },
  methods: {
    async startScan() {
      this.refCode = null
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        })
        this.$refs.video.srcObject = stream
        this.$refs.video.style.display = 'block'
        this.scanQRCode()
      } catch (error) {
        console.error('Error accessing the camera', error)
      }
    },
    scanQRCode() {
      const canvasElement = document.createElement('canvas')
      const canvas = canvasElement.getContext('2d')
      const video = this.$refs.video
      canvasElement.style.display = 'block'

      const scan = () => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvasElement.height = video.videoHeight
          canvasElement.width = video.videoWidth
          canvas.drawImage(
            video,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          )
          const imageData = canvas.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
          )
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert'
          })

          if (code) {
            this.refCode = code.data
            // Stop the stream and video
            video.srcObject.getTracks().forEach(track => track.stop())
            video.style.display = 'none'
            canvasElement.style.display = 'none'
          } else {
            requestAnimationFrame(scan)
          }
        } else {
          requestAnimationFrame(scan)
        }
      }
      scan()
    },
    onTime(promotion) {
      let isOnTime = true
      let publishUp = promotion.publishUp
      const publishUpProd = get(promotion, 'publishUpProd', null)

      if (this.$env.ENVIRONMENT_NAME === 'prod' && publishUpProd) {
        publishUp = publishUpProd
      }
      const start = `${publishUp} 00:00:00 GMT+07:00`.replace(/-/g, '/')

      if (isEmpty(promotion.publishDown)) {
        isOnTime = isAfter(new Date(), new Date(start))
      } else {
        const down = `${promotion.publishDown} 23:59:59 GMT+07:00`.replace(
          /-/g,
          '/'
        )
        isOnTime = isWithinInterval(
          new Date(), // Now
          {
            start: new Date(start),
            end: new Date(down)
          }
        )
      }
      const haveFeatureToggle =
        typeof promotion.featureToggle === 'string'
          ? this.$features(promotion.featureToggle)
          : promotion.featureToggle

      return haveFeatureToggle && isOnTime
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    handleClickCal() {
      this.$analytics.vehicleSelectPage.landforms({
        source: 'home page',
        action: 'click estimate button on home page'
      })
      this.$gtag.event('click', {
        event_action: 'select_vehicle',
        event_label: 'homepage'
      })
      this.$router.push({
        path: '/vehicle-select'
      })
    },
    handleClickLoan() {
      this.$gtag.event('click', {
        event_action: 'click_loan_request',
        event_label: 'homepage'
      })
      this.$ttq.track('click-btn-loan-request-home')
      this.$router.push({
        path: '/lead-form',
        query: {
          ...this.$route.query,
          clickLeadForm: 'y',
          events: 1,
          click: 'form',
          subCampaignName: 'EFORM'
        }
      })
    },
    bannerClick(item) {
      this.$gtag.event('click', {
        event_action: 'click_banner_from_carousel',
        event_label: item.utmCampaign
      })
      this.$router.push({
        path: item.url,
        query: {
          events: '2'
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.control-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 65px;
  height: 100%;
  @media (max-width: 1119px) {
    gap: 30px;
  }
  @media (max-width: 991px) {
    gap: 24px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 5px;
  }
}
.text-promote {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  span {
    display: inline-block;
  }
  .first-text {
    font-size: 24px;
    font-weight: 500;
    color: #212121;
    margin-right: 16px;
    @media (max-width: 1119px) {
      font-size: 18px;
    }
    @media (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .special-text {
    color: #ec222a;
    font-size: 26px;
    font-weight: 700;
    @media (max-width: 1119px) {
      font-size: 20px;
    }
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 36.4px;
      margin-bottom: 4px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
  .last-text {
    font-size: 18px;
    font-weight: 500;
    color: #757575;
    display: block;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
.revamp-wrapper-carousel {
  margin-top: 45px;
  @media (max-width: 991px) {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    margin-top: 16px;
  }
}
#revamp-carousel-promotions .carousel-inner {
  max-width: 1200px;
  width: 100%;

  @include mobile {
    max-width: 768px;
    width: 100%;
  }
}
.mobile {
  display: none;
  @include mobile {
    display: inline;
  }
}
.revamp-banner-promote {
  display: grid;
  grid-template-columns: 142px auto;
  width: 100%;
  background-image: url('~assets/images/revamp/bg-revamp-promote.svg');
  background-repeat: no-repeat;
  border-radius: 16px;
  background-size: cover;
  margin-top: 60px;
  align-items: flex-end;
  min-height: 111px;
  height: 100%;
  padding: 0px 32px;
  @media (max-width: 991px) {
    padding: 16px;
  }
  @media (max-width: 767px) {
    justify-content: space-between;
    padding: 16px;
    padding-left: 0;
  }
  .wrap-image {
    position: relative;
    img {
      position: absolute;
      left: 0;
      bottom: 0px;
      height: 150px;
      @media (max-width: 991px) {
        bottom: -16px;
      }
      @media (max-width: 767px) {
        height: 209px;
        left: -15px;
      }
      @media (max-width: 376px) {
        height: 195px;
      }
    }
  }

  .wrapper-btn {
    display: flex;
    gap: 16px;
    @media (max-width: 991px) {
      flex-direction: column;
      gap: 8px;
    }
    button {
      min-width: 179px;
      height: 55px;
      border-radius: 8px;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 991px) {
        min-width: inherit;
        width: 240px;
        max-width: 240px;
        height: 46px;
      }
      @media (max-width: 767px) {
        max-width: 179px;
        width: 179px;
        min-width: inherit;
        height: 40px;
      }
    }
    .calculate {
      border: 2px solid #ffd400;
      background: #ffffff;
    }
  }
}

.revamp-key-value,
.section-detail {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 16px;
    color: #1a1a1a;
  }
  .grid-column {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 40px;
    }
  }
  .item-column {
    text-align: center;
    max-width: 240px;
    margin: 0 auto;
    @media (max-width: 767px) {
      display: flex;
      text-align: left;
      max-width: 310px;
    }
    .image {
      @media (max-width: 767px) {
        margin-right: 16px;
      }
      img {
        object-fit: cover;
        height: 106px;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 15px;
      @media (max-width: 991px) {
        margin-bottom: 8px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    p {
      color: #616161;
      font-size: 12px;
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 10px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
      br {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
.wrap-banner {
  cursor: pointer;
}
.faq-container {
  display: flex;
  flex-direction: column;
  margin: 0px auto 40px auto;
  @media screen and (max-width: 1084px) {
    margin: 0 16px 40px 16px;
  }
  .topbar {
    display: flex;
    margin-bottom: 1.55rem;
    justify-content: space-between;
    align-items: center;
    .wrap-text {
      display: inline-block;
    }
  }
}

::v-deep #faq-header,
::v-deep #topic-footer-content {
  font-size: 32px;
  @media (max-width: 991px) {
    font-size: 20px;
  }
}
::v-deep .read-all {
  color: #594c4b;
  min-width: 101px;
  height: 36px;
  border-radius: 32px;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  size: 16px;
  @include mobile {
    width: 101px;
    size: 14px;
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
}
::v-deep .accordion-container {
  width: 100% !important;
  border-radius: 16px !important;
  border: 1px solid #bdbdbd !important;
  @media only screen and (max-width: 375px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 360px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 320px) {
    width: 100% !important;
  }
  &.isActive {
    border: 2px solid #ffd400 !important;
    background: #fff !important;
  }
  .accordion-title {
    line-height: 30px !important;
  }
  .link-properties .link {
    color: #e60000 !important;
  }
}
</style>
